<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Visualizar Contato</h4>
          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'contatos' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Contatos
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <span class="mb-2 d-block">Erro ao carregar dados!</span>
          <b-button
            variant="info"
            @click.prevent="$router.push({ name: 'contatos' })"
          >
            <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
            Listar Contatos
          </b-button>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="contact == null">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="media mb-4">
              <div class="media-body">
                <h5 class="font-size-14 mt-1">
                  <strong>Nome:</strong>
                  {{ contact.name }}
                </h5>
                <h5 class="font-size-12 mt-1">
                  <strong>Assunto:</strong>
                  {{ contact.subject }}
                </h5>
                <h5 class="font-size-12 mt-1">
                  <strong>Telefone:</strong>
                  {{ contact.phone }}
                </h5>
                <h5 class="font-size-12 mt-1">
                  <strong>E-mail:</strong>
                  {{ contact.email }}
                </h5>
                <small class="text-muted">
                  <strong>Data:</strong>
                  {{ contact.created_at | moment('DD/MM/YYYY') }} às
                  {{ contact.created_at | moment('HH:mm') }}
                </small>
              </div>
            </div>
            <p>
              {{ contact.message }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="contact.status == 1">
        <div class="card bg-primary text-white">
          <div class="card-body">
            <div class="media mb-4">
              <div class="media-body">
                <small class="text-white">
                  <strong>Data:</strong>
                  {{ contact.date_response | moment('DD/MM/YYYY') }} às
                  {{ contact.date_response | moment('HH:mm') }}
                </small>
              </div>
            </div>
            <p>
              {{ contact.message_response }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="contact.status == 0">
        <div class="card">
          <div class="card-body">
            <b-form-textarea
              id="textarea"
              v-model="contact.message_response"
              placeholder="Responder..."
              rows="8"
              max-rows="6"
            ></b-form-textarea>
            <b-button
              variant="success mt-3 float-right"
              @click.prevent="salvarData()"
            >
              <i class="bx bx-paper-plane font-size-16 align-middle mr-2"></i>
              Enviar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      contact: null,
      loading: true,
      error: false,
    }
  },
  created() {
    this.findContact()
  },
  methods: {
    ...mapActions('contact', ['ActionContactFind', 'ActionContactUpdate']),
    async salvarData() {
      try {
        var dados = {
          id: this.contact.id,
          data: this.contact,
        }
        await this.ActionContactUpdate(dados).then((res) => {
          this.contact = res.data.data
          this.$swal({
            title: 'Sucesso',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
          })
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async findContact() {
      try {
        await this.ActionContactFind(this.$route.params.id).then((res) => {
          this.contact = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.contact = null
      }
    },
  },
}
</script>
